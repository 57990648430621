<template>
  <div class="automated-geometry-container" v-if="showAutomatedGeometryValidation">
    <div  v-show="scenarioSaveInProgress">
      Saving scenario...
      <b-spinner class="ml-2" small></b-spinner>
    </div>
    <div class="mt-0" v-show="!scenarioSaveInProgress" >
      <h5 class="automated-geometry-header mb-2">
        Automated Geometry Validation
      </h5>
      <p class="automated-geometry-text">
        After reviewing the geometry integrity, click the button below to run an automated geometry validation.
      </p>
      <b-button 
        variant="primary"
        class="mt-4" 
        v-if="!this.started" 
        @click="runGeometryValidation">
        Run geometry validation
      </b-button>
      <div v-else>
        <div v-if="this.running">
          <b-spinner small class="mr-2"/>
          Automated validation in progress
        </div>
        <div v-else-if="!automatedGeometryValidationError">
          <div v-if="this.automatedGeometryValidationResults.validation_errors.length > 0">
            <b-container fluid>
              <b-row no-gutters>
                <b-col cols="10">
                  <h6>Results:</h6>
                  <ul style="width:90%" class="d-inline-block mt-0">
                    <li v-for="error of this.automatedGeometryValidationResults.validation_errors" :key="error.error_type">
                      {{ error.error_message }}
                    </li>
                  </ul>
                </b-col>
                <b-col cols="2">
                   <b-button variant="primary" @click="fixGeometryFiles">Fix geometry files</b-button>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div v-else>
            <b-icon icon='check-circle' variant="success" class="mr-2"></b-icon>
            No geometry issues detected.
          </div>
        </div>
        <div v-else>
          <b-icon icon='x-circle' variant="danger" class="mr-2"></b-icon>
          Automated geometry validation failed. This does not necessarily indicate an error with your geometry. You can continue and submit your scenario.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AutomatedGeometryValidation',
  data() {
    return {
      running: false,
      started: false,
    };
  },
  computed: {
    showAutomatedGeometryValidation() {
      return this.createScenarioStep == 1;
    },
    configurationId() {
      return Number(this.$route.params.configuration);
    },
    ...mapGetters('project', ['createScenarioStep', 'automatedGeometryValidationResults', 
      'automatedGeometryValidationError', 'scenarioSaveInProgress']),
    ...mapGetters('project/simulationAsset', ['simulation']),
    ...mapGetters('project/viewer', ['geometryFileRemoved'])
  },
  methods: {
    fixGeometryFiles() {
      // Set Error files
      const problemFiles = [];
      for (const error of this.automatedGeometryValidationResults.validation_errors) {
        problemFiles.push(...error.problem_files);
      }
      this.setFilesToFix(problemFiles);
      this.setCreateScenarioStep(0);
    },
    geometryFilesByType(geometryType) {
      return this.simulation.assets.filter(asset => asset.geometry_type === geometryType).map(asset => asset.filename);
    },
    geometryValidationPayload() {
      return {
        scenario_id: this.configurationId, 
        simulation_id: this.simulation.id,
        simulation_type: this.simulation.simulation_type,
        ground: this.geometryFilesByType('ground'), 
        study: this.geometryFilesByType('study'), 
        surrounds: this.geometryFilesByType('surround'), 
        presentation_planes: this.geometryFilesByType('presentation plane'), 
        landscaping: this.geometryFilesByType('landscaping'), 
        mitigations: this.geometryFilesByType('mitigation'),
        overlay: this.geometryFilesByType('overlay')
      };
    },
    async runGeometryValidation() {
      this.running = true;
      this.started = true;
      await this.$store.dispatch('project/runAutomatedGeometryValidation', this.geometryValidationPayload());
      this.running = false;
    },
    ...mapActions({
      setCreateScenarioStep: 'project/setCreateScenarioStep',
      setFilesToFix: 'project/setFilesToFix',
      setAutomatedGeometryValidationError: 'project/setAutomatedGeometryValidationError'
    })
  },
  watch: {
    geometryFileRemoved(newValue) {
      if (newValue) {
        this.started = false;
      }
    },
    simulation(newValue) {
      if (newValue) {
        this.started = false;
      }
    },
    createScenarioStep(newValue) {
      if(newValue == 1) {
        this.setAutomatedGeometryValidationError(false);
        this.started = false;
      }
    }
  }
};
</script>

<style scoped>
.automated-geometry-container {
  padding-top: 2em;
  padding-bottom: 4em;
  padding-left: 3em;
  padding-right: 3em;
  margin-bottom: 0;
  position: absolute;
  height: 19.75%;
  width: 76%;
  bottom: 3.188rem;
  background-color: var(--grey-100);
  left: 11%;
  border-top-right-radius: 1.563rem;
  border-top-left-radius: 1.563rem;
  z-index: 2;
}

.automated-geometry-header {
  font-size: 1.2em;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}

.automated-geometry-text {
  font-size: 0.875rem;
}

ul { 
  height: 12vh;
  overflow: auto;
}

</style>
